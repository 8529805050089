@font-face{
  font-family:"goodHeadline";
        src:url('fonts/goodHeadline.otf');  
}

@font-face{
  font-family:"oldGill";
        src:url('fonts/Gill\ Sans\ Medium.otf')
}

@font-face{
  font-family: "angryOldGill";
  src:url("fonts/Gill\ Sans\ Heavy.otf")
}

html{
  top:0;
  right:0;
  left:0;
  font-size: 11pt;  
  overflow-x: hidden;  
}

body{  
  font-family: 'oldGill','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.App{
  max-width: 100vw;
  overflow: hidden; 
}

.floatingSubtitleContainer{
  display:none;
}

.subText{
  color:darkgray;
  text-transform:uppercase;
}

.titleContainer{  
  width: 99vw;  
  margin-top: 4.6rem; 
  margin-bottom: 3rem; 
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 0.5rem;  
  position: relative;
}

.imgLinkLabelBox{
  font-size:1.1rem;
  margin-top: 1.1rem;
  width: 100vw;
  height: 4rem;
  display:flex;
  flex-direction: column;
  justify-content: space-around; 
  align-items: center;  
}

.backButtonBox{
  width:100%;
  display:flex;
  align-items: baseline;
  justify-content: center;
  margin-top:1rem;     
}

.backButton{
  font-family: "oldGill";
  font-size: 1.7rem;
  color:darkgray;
  border:none;
  background-color:transparent;
  cursor:pointer;
}

.backButton:hover{
  color:black
}

.goGreyNow{
  color:darkgray
}

.titleBox{    
  width:40vw;
  display: flex;
  justify-content: space-between;    
  align-items: center;  
  margin-top: 5rem;
}

.greyText{
  color:darkgray;
  text-transform:uppercase;  
}

h1{
  color:black;
  font-family: "goodHeadline", Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;  
  font-size: 6.5rem;    
  margin-top:0;  
  cursor:default; 
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: normal;
}

.navBarContainer{     
  width: 99vw;
  display:flex;
  justify-content: center;
  align-items: center;  
  height: 2.8rem;
  position: fixed;
  top:0;
  background-color: white;
  z-index: 5;
}

.navBar{  
  width: 40vw;
  height: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;    
}

a{
  text-decoration: none;
  font-size: 2rem;
  color:darkgray;  
}

a:hover{
  color:black;
  transition: 500ms;  
}

p{  
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.squareBox{
  width: 98.7vw;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  position:absolute;
}

.square{
  padding:1rem;
  border-style: double;
  border-color: lightgray;
  height:12rem;
  width: 18rem;
  border-radius:2rem;  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  
}

.price{  
  text-align: center;  
}

.squareButton{ 
  padding: 2rem 1rem 2rem 1rem;
  border-radius: 1rem;
  border-width: 1px;
  background-color: lightgray;
  border-color: lightgray;
  line-height: 0;
  font-size: 1.5rem;
  border-style:solid;
  color:black;
  cursor:pointer;    
}

.squareButton:hover{
  background-color: white;
  transition: 0.5s;
}

.shopLinkContainer{
  height: 80vh;
  width:100vw;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10vh; 
}

.shopLinkContainer a{
  color:black;
  border-color:darkgray;
}
.shopLinkContainer a:hover{
  transition: 0.2s;
  border-color:black;
}

.paintingLink{
  background-image: url('./images/shop/Stockroom/3_betootaHeights.jpg');
  background-position: center;
  background-size: cover;  
}

.printLink{
  background-image: url('./images/shop/SoldOutPrints/1_sohoMickey.jpg');
  background-position: center;
  background-size: cover;  
}


.backgroundTint {
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode:lighten;
}
.shopLinkBox{
  border-style: solid;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 5rem 8rem 5rem;  
  white-space: nowrap;
}

@media (orientation: landscape) and (max-width:900px){
  .shopLinkBox{
    padding:  5rem 1rem 5rem 1rem;
    
  }
}

.multiImageLabel{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  white-space: nowrap;
}

.imageSpace{
  margin-bottom: 2rem;
}

.imageLabel{
  color:black;
  margin-top: 0.5rem;
  display: flex;  
  font-size: 1.1rem;
  white-space: nowrap;
} 
.goGreyNow{
  color:darkgray
}

.slideShowContainer{  
  width:98.7vw;  
  display:flex;
  justify-content: center;
   align-items: flex-start; 
  position:relative;     
}

.slideShow{    
  width: 100%;    
  position:relative;  
}
 
.slide{       
  width: 60vw;
  display:flex;
  align-items: center;    
  justify-content: center;  
}

.slideBox{  
  width:40vw;  
  display:flex;  
  flex-direction: column;  
  justify-content: space-between;
  align-items: center;  
  cursor: grab;    
}

.slideImage{ 
  max-width: 100%;    
  max-height: 80vh;
  object-fit: contain;
} 

 .slideLabel{    
  height: 4rem;
  display:flex;
  padding-top:2%;  
  flex-direction: column;
  justify-content: space-around;
}

.sold{
  color:red;
  font-size: 1.1rem;  
}

.soldImageContainer{  
  width: 99vw;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 
.arrow{
  user-select: none;
  position:absolute;
  z-index: 9;
  width:5rem;
  height:5rem;
  top:40%;
  transition: 0.5s;  
  cursor:pointer;  
}

.arrowPic{
  width: 5rem;
  height: 5rem;
  top:0;
}

.arrow:hover{
  opacity:0.5;  
}

.arrowRight{
  right: 10vw;  
}

.arrowLeft{
  left:10vw;  
}

.soldImage{
  opacity: 0.5;  
}

.notSold{
  display:none;
}
.soldText{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color:black;
  font-size: 3rem;
  cursor:default;
}

.crossOut{
  background: linear-gradient(45deg, rgb(0,0,0,0), rgb(0,0,0,0) 45%,red 45%,red 55%, rgb(0,0,0,0) 55%, rgb(0,0,0,0));  
}

.soldImageStandAlone{
  position:relative;
  width: 90vw;
  height:80vh;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  background-image:  var(--img);
  z-index: -1;
  margin-left:auto;
  margin-right:auto;
  text-align: justify;
  text-justify:newspaper;
  font-size: 1.5rem;
}
.soldImageBox{
  position:relative;  
}

.davidCross{  
  position:absolute;
  opacity: 1;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 1;
  pointer-events: none;  
  background-image: linear-gradient(-45deg, rgb(0,0,0,0), rgb(0,0,0,0) 50%,red 40%,red 51%, rgb(0,0,0,0) 50%, rgb(0,0,0,0));
}

.bioBox{    
  width: 100%;
  display:flex;
  align-items: center;
  flex-direction: column;
  
}
.bioFact{  
  width:40vw;  
  max-width: 100%;
  display:flex;
}

.bioKey{  
  color:darkgray;
  text-align: right;
  flex:1;
  margin-right: 1rem;
}

.bioValue{
  flex:3;
}

.price{
  font-style: italic;
}

.labelText{
  line-height: 1.1rem;
  font-size: 1.1rem;  
  text-align: center;
}
.secondLine{
  color:darkgray;
  margin-top: 0.5rem;
}

h2{  
  max-width: 100%;  
  margin-top: 0;    
  font-weight:normal;
  font-size: 2.2rem;
  text-align: center;
  line-height: 2.2rem;
}
.exhibitionContainer{      
  width:100%;
  margin-right: 1.2vw;
  display:flex;
  flex-direction: column;
  align-items: center;    
}

.exhibitionBox{
  margin-bottom: 1.5rem;
  width: 55rem;
  max-width: 90vw;
  flex-direction: column;  
  font-size: 1.5rem;
  text-align: center;  
}

.exLink{
  font-size: 1.1rem;
}
.squeezeImageContainer{      
  width: 100%;
  height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;      
}

.normalImageContainer{     
  width: 100%;  
  max-height: 83vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-bottom: 8.5rem;  
}

.normalImageBox{
  width: 90vw;  
  max-height: 80vh;
  position: relative;  
}

#normalImage{
  width:100%;
  /* height:100%; */
  max-height:80vh;
  object-fit: contain;  
}

.squeezeImageBox{  
  width: 40vw;
  height: 90%;    
}

#squeezeImage{  
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.iconContainer{  
  width: 100%;
  height: 4.5rem;
  display:flex;
  justify-content: center;  
}

.iconBox{
  width: 20%;
  min-width: 12rem;
  display:flex;
  justify-content: space-between;
}

.icon{
  height: 3rem;
  width: 3rem;  
  cursor: pointer;  
}

.icon:hover{
  opacity: 0.5;
}
#shopSlideShow{
  height:null;  
}

@media(max-width:1209px){
  .navBarContainer{
    width:99vw;    
  }

  h1{
    font-size:6rem;
  }

  .exhibitionBox{
    font-size: 1.1rem;
  }  

  span{
    font-size: 1.1rem;
  }
  h2{
    font-size: 1.1rem;    
  }
}

@media(max-width:1118px){
  h1{
    font-size: 5.6rem;
  }

  .arrowLeft{
    left:17vw;
  }

  .arrowRight{
    right:17vw;
  }
}
@media(max-width:1045px){
  .squareBox{
    margin-top: 1rem;
  }
  h1{
    font-size: 5.9rem;    
  }

  .arrow{
    height: 3.5rem;
    width:3.5rem;
  }
  .arrowLeft{
    left:12vw;
  }

  .arrowRight{
    right:12vw;
  }

  .titleBox{    
    width: 33rem;
    max-width: 92vw;  
    margin-top: 6.6rem;         
  }
   
  .bioFact{  
    width:33rem;
  }

  .bioValue{
    flex:2;
    text-align: left;
  }
  
  .squeezeImageBox{
    width: 33rem;
    max-width: 92vw;
  }

  p{
    max-width: 92vw;        
  }

  .navBar{    
    width: 33rem;
    max-width: 92vw;    
  }

  .slideShowContainer{
    margin-top: 4rem;
  }

  .slideBox{
    width:33rem;
    max-width: 90vw;
  }
}
@media (max-width: 830px) {
  .imgLinkLabelBox{
    margin-top: 0.5rem;
    height:3rem;
  }
  
  .arrow{
    top:50%;
  }
  
  .arrowRight{
    right:-2rem;
    
  }
  .arrowLeft{
    left:0;
  }
}

@media (max-width: 810px) {
  .arrow{
    width:1rem;
    height:1rem;
    top:50%;
  }

  .arrowLeft{
    left:0;
  }

  .arrowRight{
    right:0;
  }
}

@media (max-width: 490px)
{
  a{
    font-size: 1.5rem;
  }

  .greyText{
    display:none;
  }

  h1{
    font-size: 28.5vw;
    line-height: 14vw;
  }

  .titleContainer{
    margin-top: 4.5rem;
    margin-left: 1vw;
    margin-bottom: 7rem;
    width:97.5vw;

  }

  .titleBox{
    width:90vw;
    justify-content: center;
  }

  .floatingSubtitleContainer{    
    position: absolute;
    top:13.7rem;
    width: 98vw;            
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 9rem;             
  }

   .subText{       
     margin-left: 1vw;
   }

   .homeTitle{
      font-size: 48.5vw;
   }

   .aboutTitle{
      font-size: 35.5vw;
      margin-top:-6.5rem;
   }

   .aboutSpacer{
    height:3rem;
   }

   .paintingTitle{
    font-size: 38.5vw;
    margin-top:-3.5rem;
    padding-left:3px;
  }
   
   .arrow{
     top:50%;
     width:1rem;
     height:1rem;     
   }
     
  .topImage{    
    margin-top:8rem;
  }  
 }
 
@media (max-width: 415px) {
  .titleContainer{
    height:1.5rem;
    margin-top: 4.7rem;    
  }

  .aboutTitle{
    margin-top:-6rem;
  }
  .paintingTitle{
    margin-top:-4.5rem;
  }
  .floatingSubtitleContainer{
    top: 14rem;
  }
  .arrowPic{
    height:2rem;
  }
}


@media (orientation: portrait) and (max-height: 630px) {
  .slideShowContainer{
    margin-top:8rem;
  }
  .topImage{
    margin-top:9rem;
  }
}
@media (orientation: portrait) and (max-height: 515px) {
  .slideShowContainer{
    margin-top:9rem;
  }

  .topImage{
    margin-top:9rem;
  }
}

@media (orientation: portrait) {

  .shopLinkContainer{
    flex-direction: column;
  }

  .shopLinkBox{
    padding: 6rem 4rem 6rem 4rem;    
  }

  .slideShowContainer{
    margin-bottom: 0;
  }

  .normalImageContainer{
    margin-bottom: 0rem;
  }
}

@media (max-width: 376px){
  .titleContainer {
    margin-top: 3.4rem;
    margin-bottom: 9rem;
  }

  .subText{    
    margin-left: 2vw;
  }

  .homeTitle{
    font-size: 49vw;
  }

  .aboutTitle{
    font-size: 35vw;
    margin-top:-5rem;
  }

  .paintingTitle{
    font-size: 38.5vw;
    margin-top: -1.5rem;
  }

  .floatingSubtitleContainer {
    top: 12rem;
  }

  .arrow{
    top:50%;
  }  
}

